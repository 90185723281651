@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.reactjs-tiptap-editor {
  background-color: rgb(55 65 81 / var(--tw-bg-opacity)) !important;
  border: 1px solid rgb(75 85 99) !important;
  border-radius: 0.375rem !important;
}

.richtext-bg-background {
  background-color: rgb(55 65 81 / var(--tw-bg-opacity)) !important;
}

.richtext-outline-1 {
  outline-width: 0px !important;
}
